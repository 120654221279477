* {
  font-family: 'Consolas', 'Monaco', 'Courier New', monospace;
}

/* CSS Variables */
:root {
  --primary-color: #0078d4;
  --error-color: #d32f2f;
  --border-color: #ddd;
  --bg-color: #fff;
  --bg-secondary: #f5f5f5;
  --text-color: #333;

  /* Spacing */
  --spacing-xs: 2px;
  --spacing-sm: 4px;
  --spacing-md: 8px;
  --spacing-lg: 16px;
  --spacing-xl: 20px;

  /* Borders */
  --border-radius-sm: 2px;
  --border-radius-md: 4px;
  --border-radius-lg: 8px;

  /* Nesting Colors */
  --nested-color-1: #2ecc71;
  --nested-color-2: #e74c3c;
  --nested-color-3: #f39c12;
  --nested-color-4: #9b59b6;
  --nested-color-5: #1abc9c;
}






/* Nested Conditions */
.nested-condition {
  margin-left: var(--spacing-xs);
  width: calc(100% - var(--spacing-xs));
  min-width: fit-content;
  box-sizing: border-box;
  position: relative;
  padding-left: var(--spacing-xs);
}


.condition-field-select {
  min-width: 300px;
}

.condition-value-input {
  min-width: 200px;
}


.resize-handle:hover,
.resize-handle.dragging {
  background-color: var(--primary-color);
}

/* Nesting Level Colors */

.nested-line[data-level="2"] {
  background-color: var(--nested-color-2);
}

.nested-line[data-level="3"] {
  background-color: var(--nested-color-3);
}

.nested-line[data-level="4"] {
  background-color: var(--nested-color-4);
}

.nested-line[data-level="5"] {
  background-color: var(--nested-color-5);
}

/* Responsive Design */
@media (max-width: 1024px) {

  .resize-handle {
    display: none;
  }
}

/* Scrollbars */
.conditions-section::-webkit-scrollbar,
.preview-section::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.conditions-section::-webkit-scrollbar-track,
.preview-section::-webkit-scrollbar-track {
  background: var(--bg-secondary);
  border-radius: var(--border-radius-md);
}

.conditions-section::-webkit-scrollbar-thumb,
.preview-section::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: var(--border-radius-md);
}

.conditions-section::-webkit-scrollbar-thumb:hover,
.preview-section::-webkit-scrollbar-thumb:hover {
  background: #999;
}


.monaco-editor-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  /* Ensures the editor respects border radius */
  background: white;
}

/* Remove the old preview styles */
.preview-section pre {
  display: none;
}

button {
  border-radius: 4px;
  white-space: nowrap;
}

button:hover {
  background-color: #106ebe;
}


.group-type-select {
  font-weight: bold;
  color: #0078d4;
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 1px 2px;
  font-size: 0.65em;
  /* Reduced from 0.7em */
}

.group-type-select:hover {
  background-color: #e0e0e0;
  border-color: #0078d4;
}

.group-type-toggle {
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 2px;
  min-width: 14px;
  height: 14px;
  font-size: 0.65em;
}

.group-type-toggle:hover {
  background-color: #e0e0e0;
  border-color: #0078d4;
}


.group-row {
  display: flex;
  align-items: center;
  gap: 8px;
}



.clear-all-button {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  min-width: 20px;
  color: #d32f2f;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.clear-all-button:hover {
  background-color: #ffd7d7;
}

.json-editor-container {
  display: none;
  /* Remove the old container */
}

/* Update condition group to maintain width */
.condition-group {
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: fit-content;
  margin-bottom: 2px;
  gap: 6px;
  padding: 6px;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background: white;
}

/* Update nested condition to prevent shifting */
.nested-condition {
  margin-left: 1px;
  /* Significantly reduced from 8px */
  width: calc(100% - 1px);
  min-width: fit-content;
  box-sizing: border-box;
  position: relative;
  /* For absolute positioning of the line */
  border-left: none;
  /* Remove the old border */
  padding-left: 1px;
}

/* Level 1 */
.condition-group>.nested-group-container>.nested-condition {
  border-left-color: #2ecc71;
  /* Green */
}

/* Level 2 */
.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition {
  border-left-color: #e74c3c;
  /* Red */
}

/* Level 3 */
.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition {
  border-left-color: #f39c12;
  /* Orange */
}

/* Level 4 */
.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition {
  border-left-color: #9b59b6;
  /* Purple */
}

/* Level 5 */
.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition {
  border-left-color: #1abc9c;
  /* Turquoise */
}

/* Update group type select colors to match their level */
.condition-group>.nested-group-container>.nested-condition .group-type-select {
  color: #2ecc71;
}

.condition-group>.nested-group-container>.nested-condition>.nested-group-container .nested-condition .group-type-select {
  color: #e74c3c;
}

.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container .nested-condition .group-type-select {
  color: #f39c12;
}

.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container .nested-condition .group-type-select {
  color: #9b59b6;
}

.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition .group-type-select {
  color: #1abc9c;
}

/* Update hover states to match their level */
.condition-group>.nested-group-container>.nested-condition .collapse-toggle:hover,
.condition-group>.nested-group-container>.nested-condition .group-type-toggle:hover {
  border-color: #2ecc71;
}

.condition-group>.nested-group-container>.nested-condition>.nested-group-container .nested-condition .collapse-toggle:hover,
.condition-group>.nested-group-container>.nested-condition>.nested-group-container .nested-condition .group-type-toggle:hover {
  border-color: #e74c3c;
}

.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container .nested-condition .collapse-toggle:hover,
.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container .nested-condition .group-type-toggle:hover {
  border-color: #f39c12;
}

.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container .nested-condition .collapse-toggle:hover,
.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container .nested-condition .group-type-toggle:hover {
  border-color: #9b59b6;
}

.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container .nested-condition .collapse-toggle:hover,
.condition-group>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container>.nested-condition>.nested-group-container .nested-condition .group-type-toggle:hover {
  border-color: #1abc9c;
}

/* Update nested group container */
.nested-group-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1px;
  overflow: hidden;
  margin-left: 0;
  padding-left: 16px;
  width: 100%;
  box-sizing: border-box;
  min-width: min-content;
}

/* Update condition container */
.condition-container {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-left: 0;
  margin-bottom: 1px;
  width: 100%;
  box-sizing: border-box;
  padding: 6px;
}


/* Update preview section to be more responsive */
.preview-section {

  /* Allow width to be controlled by style prop */
  position: sticky;
  top: 20px;
  max-height: calc(100vh - 40px);

  /* Added left padding */
}

/* Add horizontal padding to prevent content from touching screen edge */
@media (max-width: 768px) {
  .policy-editor {
    flex-direction: column;
  }

  .conditions-section,
  .preview-section {
    flex: 1 1 100%;
    width: 100%;
    min-width: 100%;
  }

  .resize-handle {
    display: none;
  }
}




.collapse-toggle:hover {
  background-color: #e0e0e0;
  border-color: #0078d4;
}

/* Remove the rotation animation */
.collapse-toggle.collapsed {
  transform: none;
}


.group-controls button {
  padding: 1px 4px;
  font-size: 0.65em;
  min-height: 16px;
  border-radius: 2px;
  flex-shrink: 0;
  white-space: nowrap;
  height: 24px;
  border: 1px solid #ddd;
  background: #f5f5f5;
  transition: background 0.3s ease; /* Smooth transition effect */
}

.group-controls button:hover {
  background: #007bff; /* Blue background on hover */
  color: #fff; /* Optional: White text for better contrast */
}


/* Make the collapse toggle more compact */
.collapse-toggle {
  padding: 0px 2px;
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
  font-size: 0.65em;
  cursor: pointer;
  min-width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1px;
  width: 24px;
}

/* Hide h2 for more space */
.policy-editor>h2 {
  display: none;
}


/* Add responsive layout for smaller screens */
@media (max-width: 1024px) {
  .policy-editor {
    flex-direction: column;
  }

  .conditions-section,
  .preview-section {
    width: 100%;
    max-width: 100%;
  }

  .preview-section {
    border-top: 1px solid #ddd;
    margin-left: 0;
  }

}


.resize-handle:hover,
.resize-handle.dragging {
  background-color: #0078d4;
}

/* Define color variables for nested conditions */
:root {
  --nested-colors: #0078d4, #2ecc71, #e74c3c, #f39c12, #9b59b6, #1abc9c;
}

/* Update nested condition to use different colors based on nesting level */
.nested-condition {
  margin-left: 8px;
  /* Reduced from 12px */
  width: calc(100% - 8px);
  min-width: fit-content;
  box-sizing: border-box;
  border-left: 3px solid var(--nested-color, #0078d4);
}

/* Define colors for different nesting levels */
.nested-condition:nth-child(6n + 1) {
  --nested-color: #0078d4;
  /* Blue */
}

.nested-condition:nth-child(6n + 2) {
  --nested-color: #2ecc71;
  /* Green */
}

.nested-condition:nth-child(6n + 3) {
  --nested-color: #e74c3c;
  /* Red */
}

.nested-condition:nth-child(6n + 4) {
  --nested-color: #f39c12;
  /* Orange */
}

.nested-condition:nth-child(6n + 5) {
  --nested-color: #9b59b6;
  /* Purple */
}

.nested-condition:nth-child(6n + 6) {
  --nested-color: #1abc9c;
  /* Turquoise */
}

/* Update group type select color to match the border */
.nested-condition .group-type-select {
  color: var(--nested-color, #0078d4);
}

/* Update collapse toggle hover color to match the border */
.nested-condition .collapse-toggle:hover {
  border-color: var(--nested-color, #0078d4);
}

/* Update group type toggle hover color to match the border */
.nested-condition .group-type-toggle:hover {
  border-color: var(--nested-color, #0078d4);
}


/* Update nested condition to use the new line element */
.nested-condition {
  margin-left: 8px;
  /* Reduced from 12px */
  width: calc(100% - 8px);
  min-width: fit-content;
  box-sizing: border-box;
  position: relative;
  /* For absolute positioning of the line */
  border-left: none;
  /* Remove the old border */
  padding-left: 2px;
  /* Reduced from 3px */
}


/* Color variations for different nesting levels - Extended to 12 colors */
.nested-line[data-level="1"] {
  background-color: #2ecc71;
  /* Green */
}

.nested-line[data-level="2"] {
  background-color: #e74c3c;
  /* Red */
}

.nested-line[data-level="3"] {
  background-color: #f39c12;
  /* Orange */
}

.nested-line[data-level="4"] {
  background-color: #9b59b6;
  /* Purple */
}

.nested-line[data-level="5"] {
  background-color: #1abc9c;
  /* Turquoise */
}

.nested-line[data-level="6"] {
  background-color: #3498db;
  /* Light Blue */
}

.nested-line[data-level="7"] {
  background-color: #e67e22;
  /* Dark Orange */
}

.nested-line[data-level="8"] {
  background-color: #8e44ad;
  /* Dark Purple */
}

.nested-line[data-level="9"] {
  background-color: #16a085;
  /* Dark Turquoise */
}

.nested-line[data-level="10"] {
  background-color: #d35400;
  /* Burnt Orange */
}

.nested-line[data-level="11"] {
  background-color: #27ae60;
  /* Dark Green */
}

.nested-line[data-level="12"] {
  background-color: #c0392b;
  /* Dark Red */
}

/* Match group type select colors with the new colors */
.nested-condition[data-depth="1"] .group-type-select {
  color: #2ecc71;
}

.nested-condition[data-depth="2"] .group-type-select {
  color: #e74c3c;
}

.nested-condition[data-depth="3"] .group-type-select {
  color: #f39c12;
}

.nested-condition[data-depth="4"] .group-type-select {
  color: #9b59b6;
}

.nested-condition[data-depth="5"] .group-type-select {
  color: #1abc9c;
}

.nested-condition[data-depth="6"] .group-type-select {
  color: #3498db;
}

.nested-condition[data-depth="7"] .group-type-select {
  color: #e67e22;
}

.nested-condition[data-depth="8"] .group-type-select {
  color: #8e44ad;
}

.nested-condition[data-depth="9"] .group-type-select {
  color: #16a085;
}

.nested-condition[data-depth="10"] .group-type-select {
  color: #d35400;
}

.nested-condition[data-depth="11"] .group-type-select {
  color: #27ae60;
}

.nested-condition[data-depth="12"] .group-type-select {
  color: #c0392b;
}

/* Match hover states with the new colors */
.nested-condition[data-depth="1"] .collapse-toggle:hover,
.nested-condition[data-depth="1"] .group-type-toggle:hover {
  border-color: #2ecc71;
}

.nested-condition[data-depth="2"] .collapse-toggle:hover,
.nested-condition[data-depth="2"] .group-type-toggle:hover {
  border-color: #e74c3c;
}

.nested-condition[data-depth="3"] .collapse-toggle:hover,
.nested-condition[data-depth="3"] .group-type-toggle:hover {
  border-color: #f39c12;
}

.nested-condition[data-depth="4"] .collapse-toggle:hover,
.nested-condition[data-depth="4"] .group-type-toggle:hover {
  border-color: #9b59b6;
}

.nested-condition[data-depth="5"] .collapse-toggle:hover,
.nested-condition[data-depth="5"] .group-type-toggle:hover {
  border-color: #1abc9c;
}

.nested-condition[data-depth="6"] .collapse-toggle:hover,
.nested-condition[data-depth="6"] .group-type-toggle:hover {
  border-color: #3498db;
}

.nested-condition[data-depth="7"] .collapse-toggle:hover,
.nested-condition[data-depth="7"] .group-type-toggle:hover {
  border-color: #e67e22;
}

.nested-condition[data-depth="8"] .collapse-toggle:hover,
.nested-condition[data-depth="8"] .group-type-toggle:hover {
  border-color: #8e44ad;
}

.nested-condition[data-depth="9"] .collapse-toggle:hover,
.nested-condition[data-depth="9"] .group-type-toggle:hover {
  border-color: #16a085;
}

.nested-condition[data-depth="10"] .collapse-toggle:hover,
.nested-condition[data-depth="10"] .group-type-toggle:hover {
  border-color: #d35400;
}

.nested-condition[data-depth="11"] .collapse-toggle:hover,
.nested-condition[data-depth="11"] .group-type-toggle:hover {
  border-color: #27ae60;
}

.nested-condition[data-depth="12"] .collapse-toggle:hover,
.nested-condition[data-depth="12"] .group-type-toggle:hover {
  border-color: #c0392b;
}

/* Add new color line element with 30 colors */
.nested-line {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
}

/* Color variations for different nesting levels - Extended to 30 colors */

/* Green */
.nested-line[data-level="2"] {
  background-color: #e74c3c;
}

/* Red */
.nested-line[data-level="3"] {
  background-color: #f39c12;
}

/* Orange */
.nested-line[data-level="4"] {
  background-color: #9b59b6;
}

/* Purple */
.nested-line[data-level="5"] {
  background-color: #1abc9c;
}

/* Turquoise */
.nested-line[data-level="6"] {
  background-color: #3498db;
}

/* Light Blue */
.nested-line[data-level="7"] {
  background-color: #e67e22;
}

/* Dark Orange */
.nested-line[data-level="8"] {
  background-color: #8e44ad;
}

/* Dark Purple */
.nested-line[data-level="9"] {
  background-color: #16a085;
}

/* Dark Turquoise */
.nested-line[data-level="10"] {
  background-color: #d35400;
}

/* Burnt Orange */
.nested-line[data-level="11"] {
  background-color: #27ae60;
}

/* Dark Green */
.nested-line[data-level="12"] {
  background-color: #c0392b;
}

/* Dark Red */
.nested-line[data-level="13"] {
  background-color: #7f8c8d;
}

/* Gray */
.nested-line[data-level="14"] {
  background-color: #f1c40f;
}

/* Yellow */
.nested-line[data-level="15"] {
  background-color: #34495e;
}

/* Navy Blue */
.nested-line[data-level="16"] {
  background-color: #e84393;
}

/* Pink */
.nested-line[data-level="17"] {
  background-color: #6c5ce7;
}

/* Indigo */
.nested-line[data-level="18"] {
  background-color: #00b894;
}

/* Mint */
.nested-line[data-level="19"] {
  background-color: #fd79a8;
}

/* Light Pink */
.nested-line[data-level="20"] {
  background-color: #fdcb6e;
}

/* Light Orange */
.nested-line[data-level="21"] {
  background-color: #00cec9;
}

/* Robin's Egg */
.nested-line[data-level="22"] {
  background-color: #55efc4;
}

/* Light Mint */
.nested-line[data-level="23"] {
  background-color: #fab1a0;
}

/* Melon */
.nested-line[data-level="24"] {
  background-color: #74b9ff;
}

/* Sky Blue */
.nested-line[data-level="25"] {
  background-color: #a29bfe;
}

/* Lavender */
.nested-line[data-level="26"] {
  background-color: #ffeaa7;
}

/* Cream */
.nested-line[data-level="27"] {
  background-color: #ff7675;
}

/* Salmon */
.nested-line[data-level="28"] {
  background-color: #81ecec;
}

/* Cyan */
.nested-line[data-level="29"] {
  background-color: #dfe6e9;
}

/* Light Gray */
.nested-line[data-level="30"] {
  background-color: #636e72;
}

/* Dark Gray */

/* Match group type select colors */
.nested-condition[data-depth="1"] .group-type-select {
  color: #2ecc71;
}

.nested-condition[data-depth="2"] .group-type-select {
  color: #e74c3c;
}

.nested-condition[data-depth="3"] .group-type-select {
  color: #f39c12;
}

.nested-condition[data-depth="4"] .group-type-select {
  color: #9b59b6;
}

.nested-condition[data-depth="5"] .group-type-select {
  color: #1abc9c;
}

.nested-condition[data-depth="6"] .group-type-select {
  color: #3498db;
}

.nested-condition[data-depth="7"] .group-type-select {
  color: #e67e22;
}

.nested-condition[data-depth="8"] .group-type-select {
  color: #8e44ad;
}

.nested-condition[data-depth="9"] .group-type-select {
  color: #16a085;
}

.nested-condition[data-depth="10"] .group-type-select {
  color: #d35400;
}

.nested-condition[data-depth="11"] .group-type-select {
  color: #27ae60;
}

.nested-condition[data-depth="12"] .group-type-select {
  color: #c0392b;
}

.nested-condition[data-depth="13"] .group-type-select {
  color: #7f8c8d;
}

.nested-condition[data-depth="14"] .group-type-select {
  color: #f1c40f;
}

.nested-condition[data-depth="15"] .group-type-select {
  color: #34495e;
}

.nested-condition[data-depth="16"] .group-type-select {
  color: #e84393;
}

.nested-condition[data-depth="17"] .group-type-select {
  color: #6c5ce7;
}

.nested-condition[data-depth="18"] .group-type-select {
  color: #00b894;
}

.nested-condition[data-depth="19"] .group-type-select {
  color: #fd79a8;
}

.nested-condition[data-depth="20"] .group-type-select {
  color: #fdcb6e;
}

.nested-condition[data-depth="21"] .group-type-select {
  color: #00cec9;
}

.nested-condition[data-depth="22"] .group-type-select {
  color: #55efc4;
}

.nested-condition[data-depth="23"] .group-type-select {
  color: #fab1a0;
}

.nested-condition[data-depth="24"] .group-type-select {
  color: #74b9ff;
}

.nested-condition[data-depth="25"] .group-type-select {
  color: #a29bfe;
}

.nested-condition[data-depth="26"] .group-type-select {
  color: #ffeaa7;
}

.nested-condition[data-depth="27"] .group-type-select {
  color: #ff7675;
}

.nested-condition[data-depth="28"] .group-type-select {
  color: #81ecec;
}

.nested-condition[data-depth="29"] .group-type-select {
  color: #dfe6e9;
}

.nested-condition[data-depth="30"] .group-type-select {
  color: #636e72;
}

/* Match hover states */
.nested-condition[data-depth="1"] .collapse-toggle:hover,
.nested-condition[data-depth="1"] .group-type-toggle:hover {
  border-color: #2ecc71;
}

.nested-condition[data-depth="2"] .collapse-toggle:hover,
.nested-condition[data-depth="2"] .group-type-toggle:hover {
  border-color: #e74c3c;
}

/* ... Continue for all 30 levels ... */
.nested-condition[data-depth="30"] .collapse-toggle:hover,
.nested-condition[data-depth="30"] .group-type-toggle:hover {
  border-color: #636e72;
}

/* Update main container */
.policy-editor {
  padding: 20px;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 0;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100vh;
  min-height: 0;
}

/* Update sections to be more consistent */
.conditions-section,
.preview-section {
  box-sizing: border-box;
  padding: 20px;
  min-width: 300px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  /* Consistent height */
  overflow: auto;
  min-height: 0;
}

/* Update conditions section specific styles */
.conditions-section {
  border-right: 1px solid #ddd;
  margin-bottom: 20px;
}

/* Update preview section specific styles */
.preview-section {
  border-left: 1px solid #ddd;
  background: #f5f5f5;
}

/* Update section headers to be consistent */
.conditions-section>h3,
.preview-section>h3 {
  margin: 0 0 20px 0;
  padding: 0 0 10px 0;
  font-size: 1.1em;
  font-weight: 600;
  color: #333;
  border-bottom: 2px solid #0078d4;
}

/* Update monaco editor wrapper */
.monaco-editor-wrapper {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  background: #f8f8f8;
  height: calc(100% - 50px);
  opacity: 0.9;
  position: relative;
  min-height: 0;
  min-width: fit-content;
  overflow-x: auto;
}

/* Add a subtle overlay to indicate read-only state */
.monaco-editor-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(248, 248, 248, 0.1);
  pointer-events: none;
}

/* Style the cursor to indicate read-only */
.monaco-editor .cursor {
  display: none !important;
}

/* Update selection color for better visibility in read-only mode */
.monaco-editor .selected-text {
  background-color: rgba(173, 214, 255, 0.5) !important;
}

/* Ensure the editor container maintains its style */
.monaco-editor-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  background: #f8f8f8;
}

/* Update resize handle */
.resize-handle {
  width: 4px;
  background-color: #ddd;
  position: relative;
  flex: 0 0 4px;
  align-self: stretch;
  transition: background-color 0.2s;
  margin: 20px 0;
  cursor: col-resize;
}

.resize-handle:hover,
.resize-handle.dragging {
  background-color: #0078d4;
}

/* Add scrollbar styling */
.conditions-section::-webkit-scrollbar,
.preview-section::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.conditions-section::-webkit-scrollbar-track,
.preview-section::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.conditions-section::-webkit-scrollbar-thumb,
.preview-section::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.conditions-section::-webkit-scrollbar-thumb:hover,
.preview-section::-webkit-scrollbar-thumb:hover {
  background: #999;
}


.condition-field,
.condition-operator,
.condition-value {
  display: flex;
  align-items: center;
}

.condition-field select,
.condition-operator select,
.condition-value input {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.condition-field {
  flex: 2;
  /* Takes up more space */
}

.condition-operator {
  flex: 1;
  /* Takes up less space than field */
}

.condition-value {
  width: 80px;
  /* Fixed width for number input */
  flex-shrink: 0;
}


.where-text {
  white-space: nowrap;
  margin-right: 8px;
}


.group-header {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
  margin-bottom: 3px;
}

.group-type-container {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 0;
  gap: 3px;
  height: 32px;
}

.group-controls {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  min-width: 0;
  align-items: center;
  max-width: 100%;
  gap: 3px;
  height: 32px;
}

.conditions-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
}



/* Make sure text doesn't overflow */
* {
  word-wrap: break-word;
  word-break: break-word;
}

/* Reduce padding in inputs and selects */
.condition-field select,
.condition-operator select,
.condition-value input {
  padding: 2px 4px;
  /* Reduced from 4px 8px */
}


/* Slightly increase padding in inputs and selects */
.condition-field select,
.condition-operator select,
.condition-value input {
  padding: 3px 6px;
  /* Increased from 2px 4px */
}





/* Add horizontal scrolling to the preview section */
.preview-section {
  overflow-x: auto;
  white-space: nowrap;
}


/* Ensure the Monaco editor container allows horizontal scrolling */
.monaco-editor-container {
  min-width: fit-content;
  overflow-x: auto !important;
}

/* Update scrollbar styling for horizontal scroll */
.preview-section::-webkit-scrollbar,
.monaco-editor-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* Height for horizontal scrollbar */
}

.preview-section::-webkit-scrollbar-track,
.monaco-editor-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.preview-section::-webkit-scrollbar-thumb,
.monaco-editor-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.preview-section::-webkit-scrollbar-thumb:hover,
.monaco-editor-container::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.condition-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.condition-icon {
  color: #666;
  font-size: 14px;
  flex-shrink: 0;
}

.condition-content {
  flex: 1;
}

.condition-type-indicator {
  font-weight: bold;
  font-size: 11px;
  width: 14px;
  /* Made slightly smaller */
  height: 14px;
  /* Made slightly smaller */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin-right: 4px;
  flex-shrink: 0;
  border: 1px solid transparent;
  line-height: 1;
  padding: 0;
  /* Added to prevent padding issues */
  box-sizing: border-box;
  /* Added to ensure consistent sizing */
}

/* Regular condition indicator */
.condition-type-indicator.regular-type {
  color: #2ecc71;
  background: #e8f8f0;
  border-color: #2ecc71;
}

/* Group condition indicator */
.condition-type-indicator.group-type {
  color: #3498db;
  background: #eaf2fa;
  border-color: #3498db;
  font-size: 11px;
  /* Explicitly set to match regular type */
}

.nested-condition-content {
  flex: 1;
  min-width: 0;
}

.count-with-actions {
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: flex-start;
}





/* Add these styles where appropriate in the file */

.not-checkbox {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 10px;
  color: #666;
  cursor: pointer;
  flex-shrink: 0;
}

.not-checkbox input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid #999;
  border-radius: 2px;
  background-color: white;
  position: relative;
  padding: 0;
  /* Explicitly disable resize for checkbox */
  resize: none;
  overflow: visible;
}

.not-checkbox input[type="checkbox"]:checked {
  background-color: #0078d4;
  border-color: #0078d4;
}

.not-checkbox input[type="checkbox"]:checked::after {
  content: '';
  left: 3px;
  top: 1px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.not-checkbox input[type="checkbox"]:hover {
  border-color: #0078d4;
}



/* Specific sizes for each field type */
.condition-field-select {
  min-width: 300px !important;
  width: 300px !important;
  max-width: 800px !important;
}


.condition-value-input {
  min-width: 200px !important;
  width: 200px !important;
  max-width: 500px !important;
}


.field-options {
  position: fixed;
  max-height: 300px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  min-width: 700px;
  width: auto !important;
  max-width: 1000px;
}

.field-option {
  padding: 8px;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
  background: white;
  width: fit-content;
  min-width: 100%;
  overflow: visible;
  padding-left: 24px;
}

.field-group-header {
  padding: 8px 12px;
  font-weight: 600;
  background-color: #f5f5f5;
  color: #666;
  font-size: 0.9em;
  border-bottom: 1px solid #e0e0e0;
}

.field-option:hover {
  background-color: #f5f5f5;
}


.tabs {
  display: flex;
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: 10px;
  background-color: #f8f8f8;
  padding: 0 10px;
}

.tab {
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  transition: all 0.2s ease;
  margin-right: 4px;
}

.tab:hover {
  color: #0078d4;
}

.tab.active {
  color: #0078d4;
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #0078d4;
}

.tab-content {
  flex: 1;
  min-height: 0;
  overflow: auto;
  padding: var(--spacing-md);
}

/* Add these styles */
.basic-info-form {
  max-width: 600px;
  padding: 0 10px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.form-group select {
  height: 35px;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #0078d4;
  box-shadow: 0 0 0 2px rgba(0, 120, 212, 0.1);
}

/* Add these styles for the parameters tab */
.parameters-form {
  max-width: 800px;
  padding: 0 10px;
}

.add-parameter-button {
  background-color: #0078d4;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
}

.add-parameter-button:hover {
  background-color: #006abc;
}

.existing-parameters {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.parameter-item {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.parameter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.parameter-header h4 {
  margin: 0;
  color: #333;
}

.parameter-details {
  font-size: 14px;
  color: #666;
}

.parameter-details>div {
  margin-bottom: 4px;
}

.remove-parameter {
  background: none;
  border: none;
  color: #666;
  font-size: 18px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
}

.remove-parameter:hover {
  background-color: #ffebee;
  color: #d32f2f;
}

.parameter-actions {
  display: flex;
  gap: 8px;
}

.edit-parameter {
  background: none;
  border: none;
  color: #666;
  font-size: 16px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
}

.edit-parameter:hover {
  background-color: #e3f2fd;
  color: #1976d2;
}

.form-group input.error {
  border-color: #d32f2f;
}

.error-message {
  color: #d32f2f;
  font-size: 12px;
  margin-top: 4px;
}


.field-option:hover {
  background-color: #f5f5f5;
}

/* Add these styles for the Details tab */
.details-form {
  max-width: 800px;
  padding: 0 10px;
}

.operation-item {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
}

.operation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.operation-header h4 {
  margin: 0;
  color: #333;
  font-size: 14px;
}

.operation-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}



.operation-fields select {
  cursor: pointer;
}

.operation-fields input:focus,
.operation-fields select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 120, 212, 0.1);
}

.add-operation-button {
  background-color: #0078d4;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.add-operation-button:hover {
  background-color: #006abc;
}

.remove-operation {
  background: none;
  border: none;
  color: #666;
  font-size: 18px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
}

.remove-operation:hover {
  background-color: #ffebee;
  color: #d32f2f;
}

.form-group select[multiple] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.form-group .help-text {
  display: block;
  color: #666;
  font-size: 12px;
  margin-top: 4px;
}

.role-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 250px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.role-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  cursor: pointer;
}

.role-item:hover {
  background-color: #f5f5f5;
}

.role-item input[type="checkbox"] {
  margin: 0;
}

.role-item span {
  font-size: 14px;
}

/* Update role selection styles */
.role-select-container {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
}

.role-search-input {
  height: 35px;
  background-color: white;
  box-sizing: border-box;
}

.role-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 0;
  z-index: 1000;
}

.role-option {
  padding: 4px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.role-option label {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.role-option input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin: 0;
  margin-right: 8px;
  flex-shrink: 0;
  position: static;
}

.role-option span {
  flex: 1;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.role-option:hover {
  background-color: #f5f5f5;
}

.selected-roles {
  margin-top: 8px;
}

.selected-role {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  margin: 2px 0;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.remove-role {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0 4px;
  font-size: 16px;
}

.remove-role:hover {
  color: #d32f2f;
}

.role-select select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.role-select select option {
  padding: 8px;
}

.role-select select option:checked {
  background-color: #0078d4;
  color: white;
}


/*----------reg condition-------*/

.condition-row {
  display: flex ;
  align-items: center ;
  gap: 0 ;
  /* Removes gap between items */
  width: 100% ;
  box-sizing: border-box ;
  flex-wrap: nowrap ;
  /* Prevents wrapping */
}

.condition-editor {
  display: flex ;
  align-items: center ;
  flex: 1 1 0 ;
  /* Allows it to grow and fill available space */
  padding: 0 ;
  /* Removes any internal padding */
  box-sizing: border-box ;
  min-width: 0 ;
  gap: 4px;
}

.searchable-dropdown {
  display: flex ;
  flex: 1 1 0 ;
  /* Fills available space */
  box-sizing: border-box ;
  min-width: 0 ;
  /* Allows it to shrink if necessary */
}

.condition-operator-select {
  width: 120px ;
  height: 32px ;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  background: white;
  resize: horizontal;
  flex: 1 1 0 ;
  /* Takes up less space than the search input */
  min-width: 100px ;
  max-width: 150px ;
  box-sizing: border-box ;
}

.condition-actions {
  display: flex ;
  gap: 4px ; /* Keeps a small gap between buttons */
  align-items: center ;
  flex: 0 1 auto ; /* Keeps it fixed in size */
  padding: 0 ; /* Removes padding */
  box-sizing: border-box ;
  margin-left: 8px ; 
}

.field-search-input {
  padding: 4px 8px ;
  border: 1px solid #ddd ;
  font-size: 12px ;
  background: white ;
  min-width: 200px ;
  /* Minimum width for responsiveness */
  max-width: 100% ;
  /* Allows it to fill the container */
  border-radius: 4px ;
  resize: horizontal ;
  overflow: auto ;
  height: 32px ;
  box-sizing: border-box ;
  /* Ensures padding and border are included in the element's total width */
  width: 100% ;
}

.count-value-input {
  padding: 4px 8px ;
  border: 1px solid #ddd ;
  font-size: 12px ;
  background: white ;
  min-width: 300px ;
  /* Minimum width for responsiveness */
  max-width: 100% ;
  /* Adjusted max-width to control field size */
  border-radius: 4px ;
  resize: horizontal ;
  overflow: auto ;
  height: 32px ;
  box-sizing: border-box ;
  width: auto ;
  /* Allows it to be smaller than the container */
  flex: 0 1 auto;
  /* Allows the field to take up only the necessary space */
}

.icon-button {
  padding: 4px 6px ;
  min-width: 20px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 2px;
  margin: 0;
  box-sizing: border-box ;
}

/* Hover effect for .icon-button */
.icon-button:hover {
  background-color: #e0e0e0;
}

/* Disabled state for .icon-button */
.icon-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Custom style for .icon-button with .remove class */
.icon-button.remove {
  background-color: #fff0f0;
  color: #d32f2f;
  font-weight: bold;
  font-size: 12px;
}

/* Hover effect for .icon-button with .remove class */
.icon-button.remove:hover {
  background-color: #ffd7d7;
}

/*----------count condition-------*/

.count-condition-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
}


.count-condition-editor {
  display: flex;
  flex: 1 1 0;
  /* Allows it to grow within the container */
  gap: 8px;
  /* Space between elements within the editor */
  min-width: 0;
  /* Ensures it shrinks as needed */
}

.condition-fields {
  display: flex ;
  gap: 8px ;
  width: 100% ;
  box-sizing: border-box;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}


.field-search-input,
.condition-operator-select {
  flex: 1 1 150px ;
  min-width: 120px ;
}

@media (max-width: 768px) {
  .condition-fields {
    flex-direction: column ;
  }
}

/* About tab styles */
.about-tab {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.about-section {
  margin-bottom: 2rem;
}

.about-section h2 {
  color: #0078d4;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.about-section p {
  line-height: 1.6;
  margin-bottom: 1rem;
  color: #323130;
}

.about-section ul,
.about-section ol {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.about-section li {
  margin-bottom: 0.5rem;
  line-height: 1.6;
}

.about-section strong {
  color: #0078d4;
}

/* Add these styles for the header icon */
.app-header {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #1b1b1b;
  color: white;
}

.app-header img {
  width: 24px;  /* Increased from default size */
  height: 24px;  /* Maintain aspect ratio */
  margin-right: 8px;  /* Add space between icon and text */
  object-fit: contain;  /* Prevent image distortion */
}

.app-title {
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin: 0;
}

/* Add disclaimer styling */
.disclaimer {
  background-color: #f8f8f8;
  border-left: 4px solid #0078d4;
  padding: 15px;
  margin-bottom: 2rem;
  border-radius: 4px;
}

.disclaimer h2 {
  color: #333;
  margin-bottom: 0.5rem;
}

.disclaimer p {
  color: #666;
  margin: 0;
  line-height: 1.5;
}


